import React, { forwardRef } from 'react';

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  errorMessage?: string;
  label?: string;
  wrapperClassName?: string;
  iconButton?: React.ReactNode;
  startAdornment?: React.ReactNode;
  endAdornment?: React.ReactNode;
  rightIcon?: React.ReactNode;
}

export const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      errorMessage,
      label,
      wrapperClassName,
      iconButton,
      startAdornment,
      endAdornment,
      rightIcon,
      ...props
    },
    ref,
  ) => {
    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
      // Prevents the default behavior of the "-" key when the input type is number and the min value is 0
      if (props.type === 'number' && e.key === '-') {
        const minValue = parseFloat(props.min as string);
        if (!Number.isNaN(minValue) && minValue === 0) {
          e.preventDefault();
        }
      }
      props.onKeyDown?.(e);
    };

    return (
      <div className={`space-y-1 ${wrapperClassName}`}>
        {label && (
          <label htmlFor={props.name} className="text-sm text-stone-500">
            {label} {props.required && <span className="text-red-500">*</span>}
          </label>
        )}
        <div className="relative w-full">
          {startAdornment && (
            <div className="absolute inset-y-0 left-0 flex items-center pl-3">
              {startAdornment}
            </div>
          )}
          <input
            {...props}
            ref={ref}
            className={`input input-bordered w-full border-gray-300 text-sm ${
              props.className
            } ${startAdornment ? 'pl-6' : ''} ${endAdornment ? 'pr-6' : ''}`}
            id={props.name}
            onKeyDown={handleKeyDown}
          />
          {endAdornment && (
            <div className="absolute inset-y-0 right-0 flex items-center pr-3">
              {endAdornment}
            </div>
          )}
          {iconButton && (
            <div className="absolute inset-y-0 right-0 flex cursor-pointer items-center pr-3">
              {iconButton}
            </div>
          )}
          {rightIcon && (
            <div className="absolute inset-y-0 right-0 flex cursor-pointer items-center pr-3">
              {rightIcon}
            </div>
          )}
        </div>
        {errorMessage && (
          <p role="alert" className="mt-1 text-sm text-red-500">
            {errorMessage}
          </p>
        )}
      </div>
    );
  },
);

Input.displayName = 'Input';
